const BASE_API_SENGUP = 'https://preprod.sengup.com/';
const BASE_API = 'http://83.166.154.165';
const BASE_ADMIN = 'https://admin.sengup.com';
const BASE_RESEAU = 'https://reseau.sengup.com';
const BASE_AUTH = 'https://bgauth.sengup.com';
const BASE_TRSF = 'https://services.sengup.com/transfert';
const BASE_WALLET = 'https://services.sengup.com/wallet';
const BASE_API_PAY = 'https://payservices.sengup.com';

export const environment = {
  production: true,
  name: 'prod',
  walletApi: BASE_WALLET + '/api',
  mauritanieApi : BASE_API + ':9080/api',
  apiDgpsn: BASE_API + ':8065/api',
  apiAuthUrl: BASE_AUTH + '/api',
  apiAdminUrl: BASE_ADMIN + '/api',
  apiTransfertUrl: BASE_TRSF + '/api',
  apiReseauUrl: BASE_RESEAU + '/api',
  apiBourseE: BASE_API + ':8005/bourses/etudiant/api',
  apiPas: BASE_API + ':8005/pasm/api',
  apiDgse: BASE_API_PAY + '/dgse/api',
  apiAssurance: 'https://services.sengup.com/assurance/api',
  urlFront:'https://caisse.nt-albey.ne/#/sessions/signin',
  urlFrontstandard:'https://pdg.sn/pos/#/sessions/signin',
  urlFrontforot:'https://pdg.sn/pos/#/sessions/forgot',
  urlFrontfirst:'https://pdg.sn/pos/#/sessions/updatePwd',
  urlFrontforotNT:'https://caisse.nt-albey.ne/#/sessions/forgot',
  urlFrontfirstNT:'https://caisse.nt-albey.ne/#/sessions/updatePwd',
};
